import React from 'react';
import { Outlet, Link } from 'react-router-dom';
import './Navbar.css'; // Import the CSS file for styling

const Navbar = () => {
    return (
        <nav className="navbar">
            <h1 className="title">
                <Link to="/" className='nav-link'>Byron's App</Link>
            </h1>
            <ul className="nav-links">
                <li className="nav-item">
                    <Link to="/" className='nav-link'>Home</Link>
                </li>
                <li className="nav-item">
                    Still under construction...
                </li>
                {/* <li className="nav-item">
                    <Link to="/todo-list" className='nav-link'>Test 3</Link>
                </li>
                <li className="nav-item">
                    <Link to="/todo-list" className='nav-link'>Test 4</Link>
                </li>*/}
                <li className="nav-item">
                    <Link to="/private" className='nav-link'>Login</Link>
                </li>
            </ul>
        </nav>
    )
}

export default Navbar;