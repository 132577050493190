import React from 'react';
import { Outlet, Link } from 'react-router-dom';
import './template-styles.css';
import Navbar from './Navbar';

const projects = [
  {
    id: 1,
    title: 'CryptoIQ',
    description: 'A cryptocurrency intelligence platform',
    link: '/crypto-iq',
    languages: 'Node.js, React',
  },
  {
    id: 2,
    title: 'Country Checklist',
    description: 'Track countries you have visited',
    link: '/country-checklist',
    languages: 'Node.js, HTML, jQuery'
  },
  {
    id: 3,
    title: 'ToDo List',
    description: 'Manage your tasks and stay organized',
    link: '/todo-list',
    languages: 'TBC'
  },
  {
    id: 4,
    title: 'Finances',
    description: 'Track finances',
    link: '/finances',
    languages: 'TBC'
  },
  {
    id: 5,
    title: 'Journal',
    description: 'Uncharted Realms: Forging the Path to Clarity',
    link: '/journal',
    languages: 'TBC'
  },
];

const Layout = () => {
  return (
    <div className='card-body'>
      <Navbar />
      {projects.map((project) => (
        <Link to={project.link} className={"card card" + project.id} key={project.id}>
          <div src={project.image} alt={project.title} className={"card-image card" + project.id} />
          <div className="card-text">            
            <h2>{project.title}</h2>
            <p>{project.description}</p>
            <span className="languages">{project.languages}</span>
          </div>
        </Link>
      ))}

    </div>
  );
};

export default Layout;

/*
<div className="layout-container">
        <nav className="navbar">
          <h1 className="logo">My App</h1>
        </nav>
        <div className="project-container">
          {projects.map((project) => (
            <Link to={project.link} className="project-card" key={project.id}>
              <div className="left-info-column">
                <div className="project-info">
                  <h2 className="project-title">{project.title}</h2>
                  <p className="project-description">{project.description}</p>
                </div>
              </div>
              <div className="project-image-wrapper">
                <img src={project.image} alt={project.title} className="project-image" />
              </div>
            </Link>
          ))}
        </div>
        <Outlet />
        </div>

        <div className="card">
        <div className="card-image" ></div>
        <div className="card-text">
          <span className="date">4 days ago</span>
          <h2>Post One</h2>
          <p>Lorem ipsum dolor sit demoise amet consectetur, Ducimusele, repudiandae temporibus omnis illum maxime quod deserunt eligendi dolor</p>
        </div>
      </div>
<div className="card rgb">
        <div className="card-image card2"></div>
        <div className="card-text card2">
          <span className="date">1 week ago</span>
          <h2>Post Two</h2>
          <p>Adipisicing elit. Ducimus, repudiandae corrupti tialeno des ameto temporibus omnis provident illum maxime quod. Lorem ipsum dolor</p>
        </div>
      </div>
      <div className="card">
        <div className="card-image card3"></div>
        <div className="card-text card3">
          <span className="date">3 week ago</span>
          <h2>Post Three</h2>
          <p>Repudiandae repudiandae de corrupti amet temporibus omnis si provident illum maxime. Ducimus, lorem ipsum dolor adipisicing elit</p>
        </div>
      </div>
*/