import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import CryptoIQ from './CryptoIQ/App';
import CountryChecklist from './CountryChecklist/App';
import ToDoList from './ToDoList/ToDoList';
import Layout from './Layout/Layout';
import Login from './Private/Login';
import Photos from './Private/Photos'
import Journal from './Journal/Journal'
/*import Finances from './'*/

function App() {
  return (
    <React.StrictMode>
      <Routes>
        <Route path="/" element={<Layout />} />
        <Route path="/crypto-iq" element={<CryptoIQ />} />
        <Route path="/country-checklist" element={<CountryChecklist />} />
        <Route path="/todo-list" element={<ToDoList />} />
        <Route path="/private" element={<Login />} />
        <Route path="/photos" element={<Photos />} />
        <Route path="/journal" element={<Journal />} />
        {/*<Route path="/finances" element={<Finances />} />*/}
      </Routes>
    </React.StrictMode>
  );
}

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root')
);
