import React, {useEffect, useState} from 'react';

function App() {
    return (
        <div className="coin-app">
            Country Checklist App
        </div>
    )
}

export default App;
