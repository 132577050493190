import React, { useState } from 'react';
import { Form, Button } from "react-bootstrap";
import {Link, Routes, Route, useNavigate} from 'react-router-dom';
import './Login.css';

const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();

    function validateForm() {

        return email.length > 0 && password.length > 0;

    }

    function handleSubmit(event) {
        event.preventDefault();

        navigate('/photos')
    }

    return (
        <div className="login-container">
            <h2 className="login-heading">Login</h2>
            <Form onSubmit={handleSubmit}>

                <Form.Group className='form-group' controlId="email">
                    <Form.Label class='label'>Email</Form.Label>
                    <Form.Control
                        autoFocus
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </Form.Group>

                <Form.Group className='form-group' controlId="password">
                    <Form.Label class='label'>Password</Form.Label>
                    <Form.Control
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />

                </Form.Group>

                <Button type="submit" disabled={!validateForm()}>
                    Login
                </Button>

            </Form>
        </div >
    )
}

export default Login;