import React, { useState } from 'react';
import { Form, Button } from "react-bootstrap";
import {Link, Routes, Route, useNavigate} from 'react-router-dom';
import './Login.css';

const Photos = () => {
    const navigate = useNavigate();

    return (
        <div className="login-container">
            Photos
        </div>
    )
}

export default Photos;