import React, { useState } from "react";
import "./Journal.css";
import Navbar from "../Layout/Navbar";
import "firebase/storage";

const Journal = () => {
  const [entry, setEntry] = useState("");

  const [editedText, setEditedText] = useState("");

  const handleEntryChange = (event) => {
    setEntry(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      // Get a reference to the CSV file in Firebase Storage
      //   const storageRef = firebase.storage().ref();
      //   const csvFileRef = storageRef.child("journal_entries.csv");

      //   // Get the current content of the CSV file
      //   const currentContent = await csvFileRef.getDownloadURL();

      //   // Append the new entry to the current content
      //   const newContent = `${currentContent}\n${entry}`;

      //   // Upload the updated content back to the CSV file
      //   await csvFileRef.putString(newContent);

      // Clear the input field
      setEntry("");

      console.log("Entry added successfully");
    } catch (error) {
      console.error("Error adding entry:", error);
    }
  };

  return (
    <div className="journal-body">
      <Navbar />
      <div>
        <header>
          <h1>Your Journal App</h1>
          <nav>
            <ul>
              <li>Home</li>
              <li>Calendar</li>
              <li>Entries</li>
              <li>Profile</li>
              <li>Logout</li>
            </ul>
          </nav>
        </header>

        <main>
          <section className="journal-entry">
            <h2>Today's Journal Entry</h2>
            <p>Date: {new Date().toLocaleDateString("en-uk")}</p>

            <form onSubmit={handleSubmit}>
              <textarea value={entry} onChange={handleEntryChange} />

              <div className="rating">
                <p>Rate your day:</p>
                <p>[Stars or other rating system here]</p>
              </div>

              <button type="submit">Add Entry</button>
            </form>
          </section>

          <section className="inspirational-quote">
            <h2>Inspirational Quote of the Day</h2>
            <blockquote>"Inspirational quote goes here."</blockquote>
            <button>Get New Quote</button>
          </section>

          <section className="streak-and-count">
            <p>Your Current Streak: [Streak counter]</p>
            <p>Total Entries: [Total entries count]</p>
          </section>

          <section className="calendar">
            {/* Monthly calendar with highlighted days */}
          </section>
        </main>

        <footer>
          <p>&copy; 2023 Your Journal App</p>
        </footer>
      </div>
    </div>
  );
};

export default Journal;
