import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import './TodoList.css';
import Navbar from '../Layout/Navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const TodoList = () => {
  const [tasks, setTasks] = useState([
    { id: 'task1', content: 'Task 1', column: 'column1', index: 2 },    
    { id: 'task3', content: 'Task 3', column: 'column2', index: 0 },
    { id: 'task2', content: 'Task 2', column: 'column1', index: 1 },
  ]);

  const [editedText, setEditedText] = useState('');

  const handleDragEnd = (result) => {
    if (!result.destination) return;
  
    const { source, destination } = result;
  
    if (source.droppableId === destination.droppableId) {
      // Moving within the same column
      const updatedTasks = [...tasks];
      const columnTasks = updatedTasks.filter((task) => task.column === source.droppableId);
  
      // Remove the moved task from the original position
      const movedTask = columnTasks.splice(source.index, 1)[0];
  
      // Insert the moved task into the new position
      columnTasks.splice(destination.index, 0, movedTask);
      console.log(destination.index);
      console.log(source.index);
  
      // Update the indexes of tasks within the same column
      columnTasks.forEach((task, index) => {
        const taskIndex = updatedTasks.findIndex((t) => t.id === task.id);
        updatedTasks[taskIndex].index = index;
      });
  
      setTasks(updatedTasks);
    } else {
      // Moving to a different column
      const updatedTasks = tasks.map((task) => {
        if (task.id === result.draggableId) {
          return { ...task, column: destination.droppableId };
        }
        return task;
      });
  
      setTasks(updatedTasks);
    }
  };

  const handleEditTask = (id) => {
    setTasks((prevTasks) =>
      prevTasks.map((task) =>
        task.id === id ? { ...task, editing: true } : task
      )
    );

    const taskToEdit = tasks.find((task) => task.id === id);
    setEditedText(taskToEdit.content); // Use "content" instead of "text"
  };

  const handleSubmitEdit = (id) => {
    setTasks((prevTasks) =>
      prevTasks.map((task) =>
        task.id === id ? { ...task, editing: false, content: editedText } : task
      )
    );
  };

  return (
    <div>
      <Navbar />
      <DragDropContext onDragEnd={handleDragEnd}>
        <div className="board">
          <Column id="column1" title="To Do" tasks={tasks} handleEditTask={handleEditTask} handleSubmitEdit={handleSubmitEdit} editedText={editedText} setEditedText={setEditedText} />
          <Column id="column2" title="Doing" tasks={tasks} handleEditTask={handleEditTask} handleSubmitEdit={handleSubmitEdit} editedText={editedText} setEditedText={setEditedText} />
          <Column id="column3" title="Done" tasks={tasks} handleEditTask={handleEditTask} handleSubmitEdit={handleSubmitEdit} editedText={editedText} setEditedText={setEditedText} />
        </div>
      </DragDropContext>
    </div>
  );
};

const Column = ({ id, title, tasks, handleEditTask, handleSubmitEdit, editedText, setEditedText, setTasks }) => {
  const columnTasks = tasks.filter((task) => task.column === id);

  const addTask = (columnId) => {
    const newTask = {
      id: `task${tasks.length + 1}`,
      content: '',
      column: columnId,
    };
    setTasks((prevTasks) => [...prevTasks, newTask]);
  };

  return (
    <div className="column">
      <h2>{title}</h2>
      <TaskList
        droppableId={id}
        tasks={columnTasks}
        handleEditTask={handleEditTask}
        handleSubmitEdit={handleSubmitEdit}
        editedText={editedText}
        setEditedText={setEditedText}
      />
      <button onClick={() => addTask(id)}>Add Task</button>
    </div>
  );
}



const TaskList = ({ droppableId, tasks, handleEditTask, handleSubmitEdit, editedText, setEditedText }) => {
  const columnTasks = tasks.filter((task) => task.column === droppableId);

  return (
    <Droppable droppableId={droppableId}>
      {(provided) => (
        <div className="task-list" {...provided.droppableProps} ref={provided.innerRef}>
          {columnTasks.map((task, index) => (
            <Task key={task.id} id={task.id} index={index}>
              {task.editing ? (
                <div className=''>
                  <div className="task-icon">
                    <input
                      type="text"
                      value={editedText}
                      onChange={(e) => setEditedText(e.target.value)}
                    />

                    <FontAwesomeIcon
                      icon={faCheck}
                      className="fa-button"
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleSubmitEdit(task.id)}
                    />
                  </div>
                </div>
              ) : (
                <div>
                  <div className="task-icon">
                    {task.content}
                    <FontAwesomeIcon
                      icon={faPenToSquare}
                      className="fa-button"
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleEditTask(task.id)}
                    />
                  </div>
                </div>
              )}
            </Task>
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

const Task = ({ id, index, children }) => {
  return (
    <Draggable draggableId={id} index={index}>
      {(provided) => (
        <div className="task" {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
          {children}
          <button>Delete</button>
        </div>
      )}
    </Draggable>
  );
};

export default TodoList;
